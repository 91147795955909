<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/volunteers/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body v-if="!is.loading">

		<app-content-box :readonly="$authorised('con/volunteers/access', 'read')">

			<app-input-suggest label="Badge" placeholder="Search badges..." v-model="model.badge" api="convention/badge" :validation="$v.model.badge" />
			<app-input-checklist :fullWidth="true" :singular="true" :columns="1" label="Scope" v-model="model.type" :validation="$v.model.type" :options="$constants.volunteers.whitelist.typeName" :descriptions="$constants.volunteers.whitelist.typeDescription" />
			<app-input-suggest label="Roles" placeholder="Search roles..." v-model="model.roles" :multiple="true" api="convention/volunteers/role" :validation="$v.model.roles" v-if="model.type === $constants.volunteers.whitelist.type.specific" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/volunteers/access', 'read'),
			model: {
				id: false,
				badge: false,
				type: this.$constants.volunteers.whitelist.type.all,
				roles: []
			}
		}

	},

	validations: {
		model: {
			badge: {
				required
			}
		}
	},

	computed: {

		title: function() {

			return (this.isNew) ? 'Create' : this.item.badge.name
		
		}

	},

	methods: {

		onSaved: function() {

			this.$router.push({
				name: this.$route.meta.back
			})

		}

	}

}

</script>

<style scoped>

</style>